* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Arial", sans-serif;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #389e0d;
  border-radius: 4px;
}

a {
  text-decoration: none;
}

video {
  background: #000;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.container {
  padding: 0 60px;
}

.btn-form {
  background: #78be20;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  height: 40px;
}

.btn-form:hover,
.btn-form:focus {
  border-color: #78be20;
  color: #78be20;
}

.ant-btn.btn-form[disabled],
.ant-btn.btn-form[disabled]:hover {
  background: #afafaf;
  border-color: #afafaf;
  color: #fff;
}

.btn-form-link {
  padding: 0;
}

.btn-form-link span {
  color: #389e0d;
  line-height: 24px;
  text-decoration: underline !important;
}

.mb30 {
  margin-bottom: 30px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.text-center {
  text-align: center;
}

p {
  margin: 0;
}

br {
  display: block;
  content: "";
}

.btn-modal {
  background: #78be20 !important;
  border-radius: 45px;
  color: #fff !important;
  border-color: #78be20 !important;
  font-size: 20px;
  height: 40px;
}

.btn-danger {
  background: #ec1c24 !important;
  border-radius: 45px;
  color: #fff !important;
  border-color: #ec1c24 !important;
  font-size: 20px;
  height: 40px;
}

.text-italic-green {
  color: #389e0d;
  font-style: italic;
  font-size: 14px;
}

.space-full-center {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.btn-bg-white {
  border: none;
  background: #eff8ec;
  border-radius: 100px;
  color: #000;
  font-weight: 700;
  font-size: 20px;
  height: 55px;
  min-width: 200px;
}

.btn-bg-white:hover,
.btn-bg-white:focus {
  color: #78be20;
}

.bg-light-green {
  background: #eff8ec;
}

.bg-green {
  background: #74bc1f;
}

.btn-more {
  border: none;
  background: #eff8ec;
  box-shadow: 0px 12px 35px rgba(160, 121, 0, 0.2);
  border-radius: 100px;
  height: 40px;
  min-width: 200px;
}

.btn-more span {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #74bc1f;
}

.hbl-h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  background: linear-gradient(94.44deg, #74bc1f 0%, #92d246 122.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 20px;
  text-align: center;
}

.hbl-tabs .ant-tabs-nav:before {
  border: none;
}

.hbl-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}

.hbl-tabs .ant-tabs-tab {
  font-size: 20px;
  line-height: 25px;
  padding-bottom: 7px;
  padding-top: 0;
}

.hbl-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 50px;
}

.hbl-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  transition: font-weight 0.5s;
}

.hbl-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #87d828;
}

.hbl-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700;
  text-shadow: none;
  color: #000;
}

.hbl-tabs .ant-tabs-nav .ant-tabs-ink-bar {
  background: #87d828;
  height: 3px;
}

.is-required {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  background: #ec1c24;
  width: 90px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hbl-pagination .ant-pagination-item,
.hbl-pagination .ant-pagination-jump-next,
.hbl-pagination .ant-pagination-jump-prev,
.hbl-pagination .ant-pagination-prev,
.hbl-pagination .ant-pagination-next {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.hbl-pagination .ant-pagination-prev,
.hbl-pagination .ant-pagination-next {
  width: 95px;
  border-radius: 100px;
}

.hbl-pagination .ant-pagination-item a,
.hbl-pagination .ant-pagination-jump-next a,
.hbl-pagination .ant-pagination-jump-prev a,
.hbl-pagination .ant-pagination-prev a,
.hbl-pagination .ant-pagination-next a {
  font-size: 16px;
  line-height: 30px;
}

.hbl-pagination .ant-pagination-prev a:not(a[disabled]),
.hbl-pagination .ant-pagination-next a:not(a[disabled]) {
  color: #000;
}

.hbl-pagination .ant-pagination-item-active {
  background: #74bc1f;
  border-color: #74bc1f;
}

.hbl-pagination .ant-pagination-item-active a {
  color: #fff;
}

.hbl-pagination .ant-pagination-item-link-icon {
  color: #74bc1f !important;
  font-size: 18px !important;
}

.hbl-pagination-show-total li:not(li:first-child) {
  display: none;
}

.hbl-pagination-show-total .ant-pagination-total-text {
  font-size: 20px;
  margin-bottom: 0;
}

.layout-detail {
  display: flex;
  flex-wrap: wrap;
}

.layout-detail__left {
  width: 30%;
}

.layout-detail__right {
  width: 70%;
  border: 1px solid #afafaf;
  padding: 25px 20px;
  min-height: 80vh;
}

.hbl-tabs-detail .ant-tabs-nav {
  margin-bottom: 25px;
}

.hbl-tabs-detail .ant-tabs-nav:before {
  border: none;
}

.hbl-tabs-detail .ant-tabs-tab {
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 5px;
  padding-top: 0;
  color: #afafaf;
}

.hbl-tabs-detail .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 40px;
}

.hbl-tabs-detail .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
  color: #74bc1f;
}

.hbl-tabs-detail .ant-tabs-nav .ant-tabs-ink-bar {
  background: #87d828;
  height: 2px;
}

.hidden {
  visibility: hidden;
}

.course-detail-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.course-detail-item img {
  margin-right: 20px;
}

.course-detail-item__title a,
.course-detail-item__title h3 {
  font-size: 20px;
  line-height: 1;
  color: #5c5c5c;
  transition: 0.5s;
  margin-bottom: 8px;
  cursor: pointer;
}

.course-detail-item__title a:hover,
.course-detail-item__title h3:hover {
  color: #74bc1f;
}

.course-detail-item__title p {
  font-size: 18px;
  line-height: 21px;
  color: #ababab;
  margin-bottom: 0;
}

.btn-primary {
  background: #74bc1f;
  height: 44px;
  min-width: 180px;
  border-color: #74bc1f;
  border-radius: 0;
  box-shadow: none;
}

.btn-primary:hover,
.btn-primary:focus {
  border-color: #74bc1f;
}

.btn-primary span {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.btn-primary[disabled] span {
  color: #5c5c5c;
}

.btn-primary:hover span,
.btn-primary:focus span {
  color: #74bc1f;
}

.btn-cancel {
  background: #eaeaea;
  height: 44px;
  min-width: 180px;
  border-color: #eaeaea;
  border-radius: 0;
  box-shadow: none;
}

.btn-cancel:hover,
.btn-cancel:focus {
  border-color: #000;
}

.btn-cancel span,
.btn-cancel:hover span,
.btn-cancel:focus span {
  color: #000;
  font-weight: 700;
  font-size: 16px;
}

.btn-cancel[disabled] span {
  color: #5c5c5c;
}

.space-full-right {
  width: 100%;
  justify-content: end;
  align-items: center;
}

.data-empty {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lottie-empty {
  width: 200px;
  margin: 0 auto;
}

.lottie-loading {
  width: 100px;
  margin: 0 auto;
}

.data-empty p,
.loading-detail p {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #74bc1f;
}

.loading-detail {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hbl-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #74bc1f;
  border-color: #74bc1f;
}

.hbl-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.hbl-checkbox .ant-checkbox-checked:after {
  border-color: #74bc1f;
}

.is-mobile {
  min-height: calc(100vh - 280px);
  text-align: center;
  margin-bottom: 0 !important;
}

.is-mobile p {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 1.3 !important;
  color: #74bc1f !important;
}

.is-mobile h3 {
  font-size: 30px !important;
  font-weight: 700 !important;
  color: #74bc1f !important;
}

.div-relative {
  position: relative;
}

.div-absolute-full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.layout-glass-bg {
  display: flex;
  flex-direction: column;
  height: 100%;
  --border-width: 4px;
}

.space-glass-bg {
  flex: 1;
  /* background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */
}

.layout-glass-main {
  width: 360px;
  height: 233px;
}

.layout-glass-main.face {
  width: 200px;
  height: 200px;
}

.bd-top,
.bd-bottom {
  position: absolute;
  left: 0;
  right: 0;
  height: 13%;
}

.bd-top {
  top: 0;
}

.bd-bottom {
  bottom: 0;
}

.bd-top::before,
.bd-top::after,
.bd-bottom::before,
.bd-bottom::after {
  position: absolute;
  content: "";
  height: 100%;
  aspect-ratio: 1/1;
}

.bd-top::before,
.bd-top::after {
  top: 0;
  border-top: var(--border-width) solid #ff0000;
}

.bd-bottom::before,
.bd-bottom::after {
  top: 0;
  border-bottom: var(--border-width) solid #ff0000;
}

.bd-top::before,
.bd-bottom::before {
  left: 0;
  border-left: var(--border-width) solid #ff0000;
}

.bd-top::after,
.bd-bottom::after {
  right: 0;
  border-right: var(--border-width) solid #ff0000;
}

.video-bg {
  width: 100%;
  height: 100%;
}

.video-bg-before {
  outline: 8px solid #a5d691;
}

.video-bg-after {
  outline: 8px solid #389e0d;
  background: #d9d9d9;
  transform: rotate(-3deg) translateY(-1%);
  -webkit-transform: rotate(-3deg) translateY(-1%);
  -moz-transform: rotate(-3deg) translateY(-1%);
  -ms-transform: rotate(-3deg) translateY(-1%);
  -o-transform: rotate(-3deg) translateY(-1%);
  transform-origin: center bottom;
}

.btn-shadow {
  height: 44px;
  min-width: 130px;
  background: #b7ed78;
  box-shadow: inset 0px 5px 5px rgba(85, 91, 110, 0.15);
  border-radius: 30px;
}

.btn-shadow:hover,
.btn-shadow:focus {
  border-color: #74bc1f;
}

.btn-shadow span {
  color: #555b6e;
  font-weight: 700;
  font-size: 18px;
}

.btn-shadow[disabled] span {
  color: #5c5c5c;
}

.btn-shadow:hover span,
.btn-shadow:focus span {
  color: #74bc1f;
}

.break-all{
  word-break: break-all;
}

.break-word{
  word-break: break-word;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0 25px;
  }
}

@media screen and (max-width: 768px) {
  .hbl-h3 {
    font-size: 25px;
  }

  .hbl-pagination .ant-pagination-item,
  .hbl-pagination .ant-pagination-jump-next,
  .hbl-pagination .ant-pagination-jump-prev,
  .hbl-pagination .ant-pagination-prev,
  .hbl-pagination .ant-pagination-next {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
  }

  .hbl-pagination .ant-pagination-prev,
  .hbl-pagination .ant-pagination-next {
    width: 95px;
    border-radius: 100px;
  }

  .hbl-pagination .ant-pagination-item a,
  .hbl-pagination .ant-pagination-jump-next a,
  .hbl-pagination .ant-pagination-jump-prev a,
  .hbl-pagination .ant-pagination-prev a,
  .hbl-pagination .ant-pagination-next a {
    line-height: 40px;
  }

  .layout-detail__right {
    padding: 10px;
  }
}

@media screen and (max-width: 500px) {
  .btn-more {
    height: 35px;
  }

  .btn-more span {
    font-size: 16px;
    line-height: 18px;
  }

  .hbl-tabs .ant-tabs-tab {
    font-size: 16px;
    padding-bottom: 5px;
  }

  .hbl-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 12px;
  }

  .hbl-tabs .ant-tabs-nav .ant-tabs-ink-bar {
    height: 2px;
  }

  .hbl-pagination .ant-pagination-item,
  .hbl-pagination .ant-pagination-jump-next,
  .hbl-pagination .ant-pagination-jump-prev,
  .hbl-pagination .ant-pagination-prev,
  .hbl-pagination .ant-pagination-next {
    width: 30px;
    height: 30px;
  }

  .hbl-pagination .ant-pagination-prev,
  .hbl-pagination .ant-pagination-next {
    width: 85px;
    border-radius: 100px;
  }

  .hbl-pagination .ant-pagination-item a,
  .hbl-pagination .ant-pagination-jump-next a,
  .hbl-pagination .ant-pagination-jump-prev a,
  .hbl-pagination .ant-pagination-prev a,
  .hbl-pagination .ant-pagination-next a {
    font-size: 16px;
    line-height: 30px;
  }

  .hbl-pagination-show-total .ant-pagination-total-text {
    font-size: 16px;
  }
}
