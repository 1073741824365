header {
  background: #fff;
}

.header {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
  list-style: none;
  display: flex;
}

.menu .menu-item:not(.menu .menu-item:last-child) {
  margin-right: 50px;
}

.menu-item a {
  font-size: 20px;
  color: #74bc1f;
  padding-bottom: 8px;
  position: relative;
}

.menu-item a::before {
  content: "";
  width: 0;
  height: 4px;
  background: #74bc1f;
  position: absolute;
  left: auto;
  right: 0;
  top: 100%;
  transition: width 0.3s;
}

.menu-item a:hover:before {
  content: "";
  width: 100%;
  height: 4px;
  background: #74bc1f;
  position: absolute;
  left: 0;
  right: auto;
  top: 100%;
}

.menu-item a.active::before {
  width: 100%;
}

.header-avt button {
  width: 50px;
  height: 50px;
  background: #74bc1f;
  border-color: #74bc1f;
}

.header-avt button:hover,
.header-avt button:focus {
  border-color: #74bc1f;
  background: #fff;
}

.header-avt button .anticon {
  font-size: 20px;
  color: #fff;
}

.header-avt button:hover .anticon,
.header-avt button:focus .anticon {
  color: #74bc1f;
}

.header-drop-down .ant-dropdown-menu-item {
  padding: 10px 25px;
}

.header-drop-down .ant-dropdown-menu-item:hover {
  background: #74bc1f;
}

.header-drop-down .ant-dropdown-menu-title-content {
  font-size: 18px;
}

.header-drop-down
  .ant-dropdown-menu-item:hover
  .ant-dropdown-menu-title-content {
  color: #fff;
}

.header-drop-down .ant-dropdown-menu-title-content .anticon {
  margin-right: 15px;
}

.header-info {
  max-width: 150px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-info h2 {
  margin-bottom: 0;
  font-size: 16px;
  color: #74bc1f;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

@media screen and (max-width: 970px) {
  .header img {
    width: 200px;
  }

  .menu .menu-item:not(.menu .menu-item:last-child) {
    margin-right: 30px;
  }

  .menu-item a {
    font-size: 18px;
  }
}

@media screen and (max-width: 880px) {
  .header {
    height: 80px;
  }

  .header-avt button {
    width: 40px;
    height: 40px;
  }

  .menu .menu-item:not(.menu .menu-item:last-child) {
    margin-right: 20px;
  }

  .menu-item a {
    padding-bottom: 5px;
  }

  .menu-item a::before {
    height: 2px;
  }

  .header-drop-down .ant-dropdown-menu-item {
    padding: 5px 15px;
  }

  .header-drop-down .ant-dropdown-menu-title-content {
    font-size: 16px;
  }

  .header-drop-down .ant-dropdown-menu-title-content .anticon {
    margin-right: 10px;
  }
}

@media screen and (max-width: 800px) {
  .header img {
    width: 180px;
  }

  .header .logo span {
    font-size: 18px;
  }

  .menu .menu-item:not(.menu .menu-item:last-child) {
    margin-right: 15px;
  }

  .menu-item a {
    font-size: 16px;
  }
}

@media screen and (max-width: 715px) {
  .header {
    height: 65px;
  }

  .header img {
    width: 160px;
  }

  .header .logo span {
    font-size: 15px;
  }

  .header-avt button {
    width: 33px;
    height: 33px;
  }

  .header-avt button .anticon {
    font-size: 18px;
  }
}

@media screen and (max-width: 650px) {
  .header {
    flex-wrap: wrap;
    height: initial;
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .header .menu {
    order: 2;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 0;
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .header img {
    width: initial;
  }

  .header .logo span {
    font-size: 14px;
  }

  .header-drop-down .ant-dropdown-menu-title-content {
    font-size: 14px;
  }

  .header-info {
    display: none;
  }
}

@media screen and (max-width: 335px) {
  .header .logo span {
    font-size: 12px;
    max-width: 130px;
  }
}
