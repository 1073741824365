footer {
  background: #fff;
  min-height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 90px;
  display: flex;
  align-items: center;
}

.footer-left {
  max-width: 230px;
}

.footer-right {
  flex: 1;
}

/* .footer-right p:first-child {
  font-weight: 700;
} */

.footer-right p {
  font-size: 12px;
  margin-bottom: 4px;
  line-height: 18px;
}

.footer-right a {
  font-size: 12px;
  color: #74bc1f;
}

.policy-and-privacy {
  background: #000;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.policy-and-privacy a {
  text-decoration: underline;
  color: #fff;
  font-size: 12px;
}

@media screen and (max-width: 880px) {
  .footer-left img {
    width: 170px;
  }
}

@media screen and (max-width: 500px) {
  .policy-and-privacy {
    padding: 10px 25px;
    height: initial;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
  }

  .footer-left {
    margin-bottom: 10px;
  }
}
