.logo {
  display: flex;
  align-items: flex-start;
}

.logo-link {
  padding-right: 10px;
  border-right: 1px solid #000;
  margin-right: 10px;
}

.logo span {
  font-size: 20px;
}

@media screen and (max-width: 500px) {
  .logo span {
    font-size: 16px;
    font-weight: 700;
  }
}

@media screen and (max-width: 390px) {
  .logo-link {
    padding-right: 5px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 374px) {
  .logo span {
    font-size: 12px;
  }
}
