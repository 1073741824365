/* CSS LOGIN PAGE */
.login-page {
  height: 100vh;
  background: #eff8ec;
  position: relative;
}

.login-page .login-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}

.login-page .login-left {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.login-page .login-right {
  width: 100%;
  height: 100%;
}

.login-page .login-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 560px) {
  .login-page .login-left {
    padding: 25px;
  }
}

@media screen and (max-height: 800px) {
  .login-page {
    height: initial;
    min-height: 100vh;
  }

  .login-page .login-right {
    height: initial;
  }

  .login-page .login-left > div:first-child {
    margin-top: 3% !important;
  }
}

@media screen and (max-width: 991px) {
  .login-page .login-footer {
    position: initial;
  }
}

/* CSS HOME PAGE */
.introduce {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.introduce-content {
  display: flex;
  justify-content: space-between;
}

.introduce-content .introduce-item:not(.introduce-item:last-child) {
  margin-right: 80px;
}

@media screen and (max-width: 880px) {
  .introduce-content .introduce-item:not(.introduce-item:last-child) {
    margin-right: 40px;
  }
}

@media screen and (max-width: 600px) {
  .introduce {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .introduce-content .introduce-item:not(.introduce-item:last-child) {
    margin-right: 0;
  }

  .introduce-content {
    flex-direction: column;
  }
}

/* CSS COURSE DETAIL PAGE */
.course-detail {
  margin-bottom: 80px;
}

.course-detail .layout-detail__left {
  padding-top: 50px;
}

.course-info {
  margin-top: 35px;
  padding: 0 20px;
}

.course-info img {
  width: 100%;
  height: 130px;
  object-fit: cover;
  background: #b6b6b6;
}

.course-info__content p {
  font-size: 22px;
  line-height: 25px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.course-info__content .is-required {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .course-detail {
    flex-direction: column;
  }

  .course-detail .layout-detail__left,
  .course-detail .layout-detail__right {
    width: 100%;
  }

  .course-detail .layout-detail__left {
    padding-top: 10px;
    margin-bottom: 30px;
    align-items: flex-start;
  }

  .course-detail .layout-detail__left .course-info {
    margin-top: 0;
  }

  .course-detail .layout-detail__left img {
    display: none;
  }
}

/* CSS CHAPTER DETAIL PAGE */
.chapter-detail {
  margin-bottom: 140px;
}

.chapter-detail__top {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
}

.chapter-detail .layout-detail__left h4 {
  font-size: 18px;
  line-height: 21px;
  color: #4e870e;
  max-width: 240px;
  margin: 0 auto 40px;
}

.chapter-detail .layout-detail__left ul {
  list-style: none;
  margin-bottom: 0;
  padding-right: 25px;
}

.chapter-detail .layout-detail__left ul li .course-detail-item {
  margin-bottom: 0;
}

.chapter-detail .layout-detail__left ul li {
  padding: 5px 25px;
  margin-bottom: 20px;
  transition: 0.5s;
}

.chapter-detail .layout-detail__left ul li:hover {
  background: #f0f0f0;
  cursor: pointer;
}

.chapter-detail .layout-detail__left ul li:hover .course-detail-item__title h3 {
  color: #74bc1f;
}

.content-chapter h2 {
  font-size: 30px;
  margin-bottom: 35px;
}

.chapter-detail .btn-back {
  white-space: initial;
  height: initial;
}

@media screen and (max-width: 900px) {
  .chapter-detail .layout-detail__left ul {
    padding-right: 5px;
  }
  .chapter-detail .layout-detail__left ul li {
    padding: 5px 10px;
  }
}

@media screen and (max-width: 768px) {
  .chapter-detail .layout-detail__left,
  .chapter-detail .layout-detail__right {
    width: 100%;
  }

  .chapter-detail .layout-detail__left h4 {
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 18px;
  }

  .content-chapter h2 {
    font-size: 27px;
    margin-bottom: 20px;
  }
}

/* CSS TEST DETAIL PAGE */
.test-detail {
  margin-bottom: 35px;
}

.test-detail.exam {
  padding-top: 30px;
  height: calc(100vh - 280px);
}

.test-detail h2 {
  font-size: 27px;
  line-height: 1;
  margin-bottom: 40px;
}

.test-detail.exam h2 {
  font-weight: 700;
  color: #74bc1f;
  font-size: 22px;
}

.test-detail .layout-detail__left {
  display: flex;
  flex-direction: column;
}

.layout-detail__left--box {
  width: 150px;
  margin: 15px 50px 0 auto;
}

.layout-detail__left--box h3 {
  margin-bottom: 5px;
  text-align: right;
  font-weight: 700;
}

.layout-detail__left--box h3:first-child {
  font-size: 22px;
  line-height: 25px;
}

.layout-detail__left--box h3:last-child {
  font-size: 28px;
  line-height: 30px;
  color: #74bc1f;
}

.layout-detail__left video {
  width: 100%;
  margin-bottom: 10px;
}

.test-detail.exam .layout-detail__left .btn-primary {
  margin-right: 50px;
  min-width: 150px;
  height: 35px;
}

.test-detail .layout-detail__right {
  padding: 40px;
  height: 80vh;
  overflow-y: auto;
}

.test-detail.exam .layout-detail__right {
  height: 100%;
  min-height: initial;
}

.test-detail .space-full-right {
  margin-top: 20px;
}

.test-detail .progress-of-test {
  box-shadow: none;
  padding-right: 0;
}

.test-detail .progress-of-test__left,
.test-detail .progress-of-test__right a {
  visibility: hidden;
}

.test-detail .progress-of-test__right {
  border: none;
}

.modal-test .ant-modal-body {
  padding: 30px;
}

.modal-test .ant-modal-confirm-title {
  font-weight: 700;
  font-size: 27px;
  line-height: 31px;
  color: #74bc1f;
  margin-bottom: 15px;
}

.modal-test .ant-modal-confirm-content {
  padding-bottom: 20px;
}

.modal-test .ant-modal-confirm-content p {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 15px;
}

.modal-test .ant-modal-confirm-btns {
  text-align: center;
}

.visited {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visited h3 {
  font-size: 30px;
  font-weight: 700;
  color: #74bc1f;
}

.ai-alert .ant-modal-body {
  padding: 0;
}

.ai-alert .ai-alert__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #e84d4f;
}

.ai-alert .ai-alert__icon .anticon {
  font-size: 30px;
  color: #fff;
}

.ai-alert .ant-modal-confirm-content {
  margin-top: 0;
}

.ai-alert .ant-modal-confirm-content h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.ai-alert .ant-modal-confirm-content p {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  padding: 10px;
}

.ai-alert .ant-modal-confirm-btns {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.ai-alert .ant-modal-confirm-btns .ant-btn-primary {
  width: 120px;
  border-color: #e84d4f;
  background-color: #e84d4f;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .test-detail.exam {
    flex-direction: column;
  }

  .test-detail .layout-detail__left,
  .test-detail .layout-detail__right {
    width: 100%;
  }

  .test-detail .layout-detail__left {
    flex-direction: row;
    padding: 15px 25px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  .test-detail .layout-detail__left video {
    width: 300px;
    margin-left: 20px;
  }

  .test-detail .layout-detail__left--box {
    margin: 30px auto 10px;
  }

  .test-detail .layout-detail__right {
    height: initial;
  }
}

@media screen and (max-width: 700px) {
  .test-detail .layout-detail__right {
    padding: 20px 15px;
  }

  .test-detail .layout-detail__left video {
    width: 250px;
  }

  .test-detail .layout-detail__left--box h3 {
    line-height: 1;
  }

  .test-detail .layout-detail__left--box h3:first-child {
    font-size: 24px;
  }

  .test-detail .layout-detail__left--box h3:last-child {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .test-detail .layout-detail__left video {
    width: 200px;
  }

  .test-detail .layout-detail__left--box h3:first-child {
    font-size: 20px;
  }

  .test-detail .layout-detail__left--box h3:last-child {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .test-detail .layout-detail__left video {
    width: 130px;
  }

  .test-detail .layout-detail__left--box h3:first-child {
    font-size: 18px;
  }
}

@media screen and (max-width: 380px) {
  .test-detail .layout-detail__left video {
    width: 110px;
  }

  .test-detail .layout-detail__left--box h3:first-child {
    font-size: 12px;
  }
}

@media screen and (max-height: 700px) {
  .test-detail.exam {
    height: 700px;
  }
}

/* CSS NEWS DETAIL PAGE */
.news-detail .layout-detail__right {
  padding: 30px 50px;
}

.news-detail .layout-detail__right h2 {
  font-size: 34px;
  line-height: 1;
  margin-bottom: 15px;
}

.news-detail img {
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .news-detail .layout-detail__left {
    margin-bottom: 0;
  }

  .news-detail .layout-detail__right {
    padding: 20px 10px;
  }

  .news-detail .layout-detail__right h2 {
    font-size: 24px;
  }
}

/* CSS MY INFO PAGE */
.page-info {
  margin-top: 20px;
  margin-bottom: 210px;
}

.page-info__modal .form-login {
  max-width: none;
  width: 100%;
  margin-top: 0;
}

.page-info__modal .ant-modal-header {
  background: #eff8ec;
  border-bottom: none;
}

.page-info__modal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  font-weight: 700;
  color: #4e870e;
}

.page-info__modal .ant-modal-body {
  background: #eff8ec;
}

.my-info {
  display: flex;
}

.page-info-wrapper {
  width: 1440px;
}

.space-empty {
  flex: 1;
}

.space-empty--gray {
  background: #f5f5f5;
}

.my-info-top {
  display: flex;
  padding-right: 0;
}

.my-info-top__left {
  width: 35%;
  padding: 30px 20px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-info-top__left .btn-primary {
  margin-top: 20px;
}

.top-left-content {
  width: 100%;
}

.top-left-content h2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  text-transform: uppercase;
  color: #6abf47;
  margin-bottom: 16px;
}

.top-left-content p {
  font-size: 18px;
  line-height: 21px;
  color: #5c5c5c;
  margin-bottom: 10px;
}

.top-left-content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 25px;
  border: 1px solid #6abf47;
  margin-bottom: 10px;
}

.top-left-content-box span {
  font-size: 18px;
  color: #6abf47;
}

.top-left-content-box span:last-child {
  font-size: 27px;
}

.top-left-content-box--primary {
  background: #6abf47;
}

.top-left-content-box--primary span {
  color: #fff;
}

.my-info-top__right {
  width: 65%;
  padding: 40px 60px 40px 40px;
  background: #f5f5f5;
}

.top-right-content {
  max-width: 930px;
  display: flex;
  flex-wrap: wrap;
}

.top-right-content__calendar {
  width: 300px;
  margin-right: 30px;
}

.top-right-content__calendar .ant-picker-calendar-mini {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.top-right-content__calendar
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background: #6abf47;
}

.top-right-content__calendar
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector,
.top-right-content__calendar
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner::before,
.top-right-content__calendar
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner:before,
.top-right-content__calendar
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover,
.top-right-content__calendar
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child,
.top-right-content__calendar
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #6abf47;
  box-shadow: none;
}

.top-right-content__calendar
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.top-right-content__calendar .ant-radio-button-wrapper:hover {
  color: #6abf47;
}

.top-right-content__calendar
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):before {
  background-color: #6abf47;
}

.top-right-content__exam {
  flex: 1;
  padding-left: 30px;
  border-left: 1px solid #e1e1e1;
  display: flex;
  flex-direction: column;
}

.top-right-content__exam h3 {
  font-weight: 700;
  font-size: 27px;
  line-height: 31px;
  color: #74bc1f;
  margin-bottom: 30px;
}

.info-exam-item {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #dcdcdc;
  align-items: center;
  justify-content: space-between;
  margin-bottom: auto;
}

.info-exam-item p {
  font-size: 14px;
}

.info-exam-item h4 {
  font-size: 18px;
  margin-bottom: 0;
}

.info-exam-item button {
  background: transparent;
  min-width: 90px;
  border-color: #adadad;
  border-radius: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.info-exam-item button:hover,
.info-exam-item button:focus {
  background: #92d246;
  border-color: #92d246;
}

.info-exam-item button span {
  color: #adadad;
}

.info-exam-item button:hover span,
.info-exam-item button:focus span {
  color: #fff;
}

.page-info .my-info:first-child {
  margin-bottom: 30px;
}

.my-info-footer {
  padding-left: 0;
}

.my-info-footer__content {
  background: #f5f5f5;
  padding: 30px 70px 30px 100px;
  display: flex;
}

.my-info-footer__content .my-info__col {
  flex: 1;
}

.my-info-footer__content .my-info__result {
  margin-right: 50px;
}

.my-info-footer__content .my-info__col h3 {
  font-weight: 700;
  font-size: 27px;
  line-height: 31px;
  color: #74bc1f;
  margin-bottom: 50px;
}

.my-info__list {
  display: flex;
  flex-direction: column;
}

.my-info__item {
  padding: 10px 15px;
  background: #74bc1f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  height: 45px;
}

.my-info__item .my-info__item--title {
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  max-width: 335px;
}

.my-info__item .my-info__item--action {
  min-width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-info__item .my-info__item--action span.anticon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.my-info__item--result {
  min-width: 125px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.my-info__item--result span {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
}

.my-info__item--result span.pass {
  color: #74bc1f;
}

.my-info__item--result span.fail {
  color: #ec1c24;
}

.my-info__item--result span.quit {
  color: #ffac32;
}

.modal-certificate .ant-modal-body {
  padding: 0;
}

.modal-certificate .ant-modal-confirm-content {
  margin-top: 0;
}

.modal-certificate .ant-modal-confirm-content img {
  width: 100%;
  display: block;
}

.modal-certificate .ant-modal-confirm-btns {
  display: none;
}

@media screen and (max-width: 1300px) {
  .top-right-content {
    flex-direction: column;
  }

  .top-right-content__calendar {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .top-right-content__exam {
    padding-left: 0;
    padding-top: 30px;
    border-left: none;
    border-top: 1px solid #e1e1e1;
  }

  .my-info-footer__content {
    flex-direction: column;
  }

  .my-info-footer__content .my-info__result {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .my-info-footer__content .my-info__col h3 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .my-info-footer__content {
    padding-left: 25px;
  }
}

@media screen and (max-width: 850px) {
  .my-info-top {
    flex-direction: column;
  }

  .my-info-top__left,
  .my-info-top__right {
    width: 100%;
  }

  .my-info-top__left {
    margin-bottom: 30px;
    justify-content: center;
  }

  .my-info-footer__content {
    padding-right: 25px;
  }
}

@media screen and (max-width: 500px) {
  .my-info-top__left {
    padding-left: 0;
  }

  .my-info__item {
    flex-direction: column;
    height: initial;
  }

  .my-info__item .my-info__item--title {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 410px) {
  .my-info-top__left {
    padding-left: 0;
  }

  .my-info__item {
    flex-direction: column;
    height: initial;
  }

  .my-info__item .my-info__item--title {
    margin-bottom: 10px;
  }

  .my-info-top__right {
    padding-left: 10px;
    padding-right: 25px;
  }

  .info-exam-item {
    flex-direction: column;
  }

  .info-exam-item__title {
    margin-bottom: 10px;
  }
}

/* CSS IDENTITY PAGE */
.identity {
  height: 100vh;
  background: #000;
}

.identity .space-empty {
  background: #000;
}

.identity__layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.identity__main {
  display: flex;
  flex-direction: column;
}

.identity__main--top {
  display: flex;
}

.identity__avatar {
  position: relative;
  height: 50vh;
  aspect-ratio: 1/1;
  background: #000;
}

.identity__avatar .video-box {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #000;
  /* background: #74bc1f; */
}
.video-box video {
  left: 50% !important;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.preview-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.identity__avatar.card .video-box {
  width: 100%;
  height: 100%;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.identity__main img {
  width: 100%;
}

.identity__main--bottom {
  flex: 1;
  background: #000;
  text-align: center;
  padding-top: 40px;
}

.identity__main--bottom h2,
.identity__main--bottom p {
  color: #fff;
  text-align: center;
}

.identity__main--bottom h2 {
  max-width: 430px;
  margin: 10px auto 10px;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
}

.identity__main--bottom p {
  max-width: 1000px;
  margin: 0 auto 10px;
  font-size: 16px;
  line-height: 21px;
}

.identity__main--bottom .btn-primary {
  min-width: 210px;
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
}

.identity .skip {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.identity .skip span {
  color: #fff;
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  cursor: pointer;
  margin: 30px 50px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.identity .skip span:hover {
  color: #74bc1f;
}

@media screen and (max-width: 600px) {
  .identity__avatar {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .identity__avatar.card {
    width: 100%;
    height: 300px;
  }

  .identity__main--bottom {
    padding-left: 10px;
    padding-right: 10px;
  }

  .identity .skip span {
    margin: 15px;
    font-size: 16px;
  }
}

@media screen and (max-width: 375px) {
  .identity__avatar {
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 320px) {
  .identity__avatar {
    width: 220px;
    height: 220px;
  }
}

/* CSS REGULATION PAGE */
.regulation {
  margin-bottom: 50px;
}

.regulation__box {
  max-width: 930px;
  margin: 50px auto 0;
  background: rgba(243, 243, 243, 0.7);
  padding: 50px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.regulation__box h2 {
  font-weight: 700;
  font-size: 27px;
  line-height: 1;
  color: #74bc1f;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.regulation p {
  font-size: 18px;
  line-height: 21px;
}

.regulation p.ai-result {
  font-size: 16px;
  text-align: center;
  margin: 10px 0 5px;
  font-weight: 700;
}

.regulation p.ai-result.error {
  color: #e84d4f;
  font-style: italic;
}

.regulation p.ai-result.success {
  color: #74bc1f;
}

.regulation__steps {
  margin: 30px 0;
}

.regulation__steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after,
.regulation__steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #74bc1f;
}

.regulation__steps .ant-steps-item-process .ant-steps-item-icon,
.regulation__steps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #74bc1f;
}

.regulation__steps .ant-steps-item-icon .anticon {
  color: #74bc1f;
}

.regulation__steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after,
.regulation__steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background: #000;
}

.regulation__steps .ant-steps-item-wait .ant-steps-item-icon {
  border-color: #000;
}

.regulation__steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #000;
}

.regulation__content {
  display: flex;
  margin-bottom: 35px;
}

.regulation__content--left {
  flex: 1;
  padding-right: 20px;
}

.regulation__content--left h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 50px;
}

.regulation__content--right {
  padding-top: 75px;
}

.regulation__content--right video {
  width: 330px;
  height: 220px;
}

.regulation__footer {
  display: flex;
  flex-direction: column;
}

.regulation .hbl-checkbox span {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
}

.regulation .hbl-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.regulation__radio {
  margin-top: 20px;
}

.regulation__radio p {
  font-size: 18px;
  line-height: 21px;
  font-style: italic;
  margin-bottom: 10px;
}

.regulation__radio .ant-radio-wrapper span:last-child {
  font-size: 18px;
  line-height: 21px;
  padding-left: 15px;
}

.regulation__radio .ant-radio-wrapper span.ant-radio .ant-radio-inner {
  border-color: #74bc1f;
}

.regulation__radio .ant-radio-wrapper span.ant-radio .ant-radio-inner::after {
  background-color: #74bc1f;
}

@media screen and (max-width: 900px) {
  .regulation {
    margin-bottom: 200px;
  }

  .regulation__box {
    margin-top: 20px;
  }
}

@media screen and (max-width: 650px) {
  .regulation p {
    max-width: initial !important;
  }

  .regulation__content {
    flex-direction: column;
  }

  .regulation__content--left {
    padding-right: 0;
  }

  .regulation__content--right {
    width: 330px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 500px) {
  .regulation__box {
    padding: 20px 10px;
  }

  .regulation__content--right {
    width: 250px;
  }

  .regulation__content--right video {
    width: 100%;
  }

  .regulation .space-full-right {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .regulation__content--right {
    width: 200px;
  }
}

/* CSS NOT FOUND PAGE */
.not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.not-found .btn-primary {
  text-transform: uppercase;
}

/* CSS CALENDAR PAGE */
.calendar {
  background-color: #eff8ec;
}

.calendar-main {
  min-height: 80vh;
  padding-top: 30px;
  padding-bottom: 80px;
}

.calendar-main__search {
  text-align: center;
}

.calendar-main .input-search {
  max-width: 930px;
  margin-bottom: 40px;
}

.calendar-main .hbl-h3 {
  margin-bottom: 30px;
}

.calendar-main__btn:hover,
.calendar-main__btn:focus {
  background: #92d246;
  border-color: #92d246;
}

.calendar-main__btn span {
  color: #adadad;
}

.calendar-main__btn:hover span,
.calendar-main__btn:focus span {
  color: #fff;
}

.calendar-main__table {
  margin-bottom: 50px;
}

.calendar-main__table .ant-table-thead > tr > th {
  background: #60bb46;
  font-weight: 700;
  font-size: 16px;
}

.calendar-main__table .ant-table-thead > tr > th::before {
  display: none;
}

.calendar-main .hbl-pagination {
  text-align: center;
}

.calendar-main__cards {
  max-width: 950px;
  margin: 0 auto;
}

/* CSS REGULATION VER 2 PAGE */
.regulationv2__main {
  margin-top: 25px;
}

.regulationv2__main .ant-card-head {
  height: 60px;
  background: #74bc1f;
}

.regulationv2__main .ant-card-head .ant-card-head-wrapper {
  height: 100%;
}

.regulationv2__main .ant-card-head-title {
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
}

.regulationv2__main .ant-card-body {
  background: #e0f2ca4d;
  padding: 0 50px 30px;
}

.regulationv2__content h3 {
  font-weight: 700;
  font-size: 28px;
  color: #389e0d;
  margin-bottom: 20px;
}

.regulationv2__content > p {
  font-size: 18px;
  line-height: 21px;
}

.regulationv2__main--camera {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.regulationv2__main--camera > p {
  max-width: 560px;
  text-align: center;
  margin-bottom: 20px;
}

.regulationv2__main--camera p.ai-result {
  font-size: 18px;
  margin-bottom: 25px;
}

.regulationv2__camera {
  /* width: 500px;
  height: 300px; */
  width: 400px;
  aspect-ratio: 4/3;
  margin-bottom: 20px;
}

.regulationv2__camera video {
  width: 100%;
  height: 100%;
}

/* CSS TEST DETAIL VER 2 PAGE */
.test-detailv2 {
  height: 100vh;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 1100px;
  margin: 0 auto;
}

.test-detailv2 .ant-card-head {
  height: 50px;
  background: #74bc1f;
}

.test-detailv2 .ant-card-head .ant-card-head-wrapper {
  height: 100%;
}

.test-detailv2 .ant-card-head-title {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  padding: 0;
}

.test-detailv2 .ant-card-body {
  background: #e0f2ca4d;
  padding: 10px 24px;
}

.test-detailv2__clock {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 10px;
  border: 1px solid #6cc149;
  border-radius: 10px;
  min-width: 100px;
}

.test-detailv2__clock img {
  width: 20px;
}

.test-detailv2__clock h3 {
  margin-bottom: 0;
  font-size: 17px;
  line-height: 1;
}

.test-detailv2 .question-item .ant-radio-wrapper span {
  font-size: 14px;
}

.test-detailv2__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.test-detailv2__control {
  width: 180px;
}

.test-detailv2__control--custom {
  display: flex;
  align-items: center;
}

.test-detailv2__control--pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 30px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #74bc1f;
  background: #d1f3c1;
}

.test-detailv2__nav,
.test-detailv2__nav:hover,
.test-detailv2__nav:focus {
  color: #389e0d;
  font-weight: bold;
  cursor: pointer;
}

.test-detailv2__nav:not(.test-detailv2__nav:disabled) .anticon,
.test-detailv2__nav:hover .anticon {
  color: #389e0d;
}

.test-detailv2__nav_disable {
  color: #888;
  cursor: not-allowed;
}

/* .test-detailv2__nav--pre {
  transform: translateX(30%);
  -webkit-transform: translateX(30%);
  -moz-transform: translateX(30%);
  -ms-transform: translateX(30%);
  -o-transform: translateX(30%);
}

.test-detailv2__nav--next {
  transform: translateX(-30%);
  -webkit-transform: translateX(-30%);
  -moz-transform: translateX(-30%);
  -ms-transform: translateX(-30%);
  -o-transform: translateX(-30%);
} */

.test-detailv2__webcam {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.test-detailv2__webcam > p {
  font-size: 14px;
  font-style: italic;
  color: #ff0000;
}

.test-detailv2__camera {
  /* width: 350px; */
  /* height: 210px; */
  height: 25vh;
  aspect-ratio: 4/3;
  margin-bottom: 15px;
}

.test-detailv2__camera video {
  width: 100%;
  height: 100%;
}

.test-detailv2__count-answers {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 10px;
  position: absolute;
  top: 60px;
  right: 24px;
}

/* .test-detailv2__count-answers h3:last-child {
  font-size: 20px;
  color: #74bc1f;
  margin-bottom: 20px;
} */

.test-detailv2 .question .ant-radio-group {
  width: 100%;
  /* height: 200px; */
  /* height: calc(100vh - 510px); */
  min-height: 50px;
  height: calc(100vh - 24vh - 280px);
  overflow-y: auto;
}

.test-detailv2__submit .btn-primary {
  height: 35px;
}

.test-detailv2__submit .btn-primary span {
  font-size: 14px;
}

.modal-resultv2 .ant-modal-content {
  background-color: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  box-shadow: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-resultv2 .ant-modal-body {
  width: 60%;
  margin: 0 auto;
}

.modal-resultv2 .ant-modal-confirm-content {
  text-align: center;
  margin-bottom: 20%;
}

.modal-resultv2 .ant-modal-confirm-content h3,
.modal-resultv2 .ant-modal-confirm-content h4 {
  font-size: 40px;
  line-height: 1.2;
}

.modal-resultv2 .ant-modal-confirm-content h3 {
  font-weight: 700;
  margin-bottom: 0;
}

.modal-resultv2 .ant-modal-confirm-content h3.pass {
  color: #7ac142;
}

.modal-resultv2 .ant-modal-confirm-content h3.fail {
  color: #969696;
}

.modal-resultv2 .ant-modal-confirm-content h4 {
  margin-bottom: 10%;
}

.modal-resultv2 .ant-modal-confirm-content span {
  font-weight: 700;
  font-size: 50px;
  line-height: 1.5;
  color: #7ac142;
}

.modal-resultv2 .ant-modal-confirm-content > p {
  font-size: 16px;
  font-weight: 700;
  color: #ec1c24;
}

.modal-resultv2 .ant-modal-confirm-btns {
  text-align: center;
}

/* CSS OPTIONS PAGE */
.options-page {
  justify-content: center;
}

.options-page-main {
  padding: 40px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.options-page-main > p {
  max-width: 500px;
  font-size: 22px;
  margin-top: 50px;
  text-align: center;
}

.options-page-main > p span,
.options-page-main > p b {
  margin-top: 15px;
  display: inline-block;
}

.options-page-main > p span.primary {
  color: #389e0d;
  font-weight: 700;
  margin-top: 0;
}

.options-page-main > p span {
  color: #ff0000;
}

.options-page-btn {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.options-page-btn .btn-shadow + .btn-shadow {
  margin-top: 15px;
}

.examSubmitBtn {
  margin-right: 10px;
  color: #fff !important;
  background-color: #3e7300 !important;
  padding: 0 30px;
  font-weight: bold;
  border-color: #3e7300 !important;
}

.camera-modal .ant-modal-body h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #e84d4f;
  margin-bottom: 0;
}

.camera-modal .ant-modal-body p {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
}

.camera-modal .ant-modal-footer {
  border: none;
  text-align: center;
}

.camera-modal .ant-modal-footer .btn-primary {
  height: 30px;
  min-width: 100px;
}

.camera-modal .ant-modal-footer .btn-primary span {
  font-size: 14px;
}
